.stats-table__header {
  text-decoration: underline;
  font-size: large !important;
  font-weight: bold;
}

.stats-table__refresh-button {
  margin-top: -4px;
  float: right;
}

.leaderboard__active-user {
  color: var(--ion-color-primary);
}

.mystats-row {
  color: var(--ion-color-primary);
}
