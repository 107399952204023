.watc-logo {
  height: 33%;
  margin: 10px auto;
}

.copyright-text {
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
}
