.game-container {
  padding: 10px;
}

.game-hud {
  color: var(--ion-color-secondary);
  text-align: center;
}

.letter-square {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  font-weight: bold;
  font-size: 42px;
  text-align: center;
  cursor: pointer;
  padding: 6px 0;
  border-radius: 4px;
  user-select: none;
}

.letter-square:hover {
  background-color: var(--ion-color-primary-shade);
}

.letter-square--disabled,
.letter-square--disabled:hover {
  background-color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-medium);
  color: var(--ion-color-medium);
  cursor: default;
}

.current-word-bar {
  border-top: 1px solid #ffffff;
  margin-top: -10px;
  padding-top: 10px;
}

.current-word-box {
  font-weight: bold;
  background-color: var(--ion-color-medium);
  min-height: 40px;
  color: var(--ion-color-medium-contrast);
  text-align: center;
  font-size: 32px;
  border-radius: 4px;
  text-transform: uppercase;
  padding: 4px 0 2px;
}

.new-best-score {
  color: var(--ion-color-success-shade);
}
