@import './ads.css';
@import './home.css';
@import './modal.css';
@import './play.css';
@import './stats.css';

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.header__player-name-label {
  vertical-align: super;
}
.header__player-icon {
  margin-right: 6px;
}

.action-sheet-button--delete-account {
  color: var(--ion-color-danger) !important;
  font-weight: bold;
}

.tertiary-color {
  color: var(--ion-color-tertiary);
}

.success-color {
  color: var(--ion-color-success);
}

.primary-color {
  color: var(--ion-color-primary);
}
